<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <!-- <v-img :src="require('@/assets/logo.png')" class="center mb-4" height="60" contain></v-img> -->
        <v-card color="transparent" class="pa-6 ma-auto" max-width="360px" elevation="0">
          <h1 class="text-h1 text-center mb-4">ERROR 401</h1>
          <p class="text-center text-danger" style="color:red">Your session was ended!</p>
          <v-btn @click="goToLogin()" block class="my-12" color="primary">Go to Login Page</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  methods: {
    goToLogin() {
      window.location.replace("/login");
    },
  },
};
</script>